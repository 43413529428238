<template>
  <CtaTeaserSection
    :id="item.fields.id"
    :color-mode="item.fields.theme"
    :background-type="backgroundType"
    :button-text="item.fields.link?.fields?.linkText"
    :button-link="buttonLink"
    :background-media="item.fields.backgroundMedia"
    :texts="splittedContent"
  >
  </CtaTeaserSection>
</template>

<script setup lang="ts">
import { CtaTeaserSection } from '@hypercodestudio/basler-components';
import type { BgType } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/CtaTeaserSection.vue';
import type { ICtaSection } from '~/lib/ContentfulService';

interface Props {
  item: ICtaSection;
}

const props = defineProps<Props>();

const buttonLink = useBuildLinkInterface(props.item.fields.link);
const backgroundType = computed((): BgType => {
  switch (props.item.fields?.backgroundType) {
    case 'Image':
      return 'image';
    case 'Animation':
      return 'animation';
    default:
      return 'color';
  }
});

const splittedContent = computed(
  () => props.item.fields.content?.split('\n') ?? []
);
</script>
